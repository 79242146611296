@import url('https://fonts.googleapis.com/css?family=Raleway:500,500i,700,700i&subset=latin-ext');

html {
  height: 100%;
}

html, body {
  margin:     0;
  padding:    0;
  font:       500 14px 'Raleway', sans-serif;
  min-height: 100%;
}

.container {
  max-width: 1200px;
  padding:   0 20px;
  margin:    0 auto;
}

.intro {
  padding:         150px 0;
  background:      url(../images/bg.jpg) no-repeat 50% 50%;
  background-size: cover;
  color:           #FFF;
  text-align:      center;
  text-transform:  uppercase;
}

div > .form-link {
  margin: 0 auto;
}

.form-link {
  overflow: hidden;

  display:  flex;

  width:    1000px;
}

.form-link input {
  line-height: 40px;
  border:      0;
  background:  #FFF;
  margin:      0;
  box-sizing:  border-box;
}

.form-link input[type=submit], .form-link  button[type=submit] {
  cursor:         pointer;

  text-transform: uppercase;
  text-align:     center;

  font-weight:    700;
  font-family:    'Raleway', sans-serif;

  background:     #2A4F86;
  color:          white;

  flex-grow:      2;
  border-radius:  5px;
  margin-left:    10px;
  border:         0;
}

.my-2 {
  margin: 1rem 0;
}
.m-auto {
  margin: 0 auto;
}

.form-link input[type=checkbox] {
  width:  10px;
  height: 10px;
}

.form-input-group {
  display: flex;
}

.form-input-group input {
  width:         100%;
  padding-left:  10px;

  border:        grey 1px solid;
  border-radius: 5px;
}

.form-input-group label {
  padding: 0 5px;
}

.form-input-group-prepend, .form-input-group-append {
  text-transform:   none;
  font-family:      apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue",
  Arial, sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  display:          flex;
  align-items:      center;

  padding:          0 10px;

  z-index:          1000;

  background-color: #2A4F86;
}

.form-input-group-prepend {
  margin-right:  -5px;

  border-radius: 5px 0 0 5px;
}

.form-input-group-append {
  margin-left:   -5px;

  border-radius: 0 5px 5px 0;
}

#checkbox-input-group {
  display:     flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  * {
    cursor: pointer;
  }
}

#linkInputGroup {
   flex-grow: 8;
}

#emailInputGroup {
  &.show {
    display:      flex
  }
  display:        none;

  flex-grow:      3;
  margin-left:    10px;
  transition: all 2s;
}

.generated-link {
  padding:        30px;
  background:     #FFF;
  border-radius:  10px 10px 0 0;
  margin:         50px 0 -150px;
  color:          #646464;
  text-transform: none;
  transition: all 2s;
}

.generated-link a {
  font-weight: 700;
  color:       #202020;
}

.text-center {
  text-align: center;
}

.showbox {
  padding: 70px 0 30px;
}

.loader {
  position: relative;
  margin:   0 auto;
  width:    100px;
}

.loader:before {
  content:     '';
  display:     block;
  padding-top: 100%;
}

.circular {
  -webkit-animation:        rotate 2s linear infinite;
  animation:                rotate 2s linear infinite;
  height:                   100%;
  -webkit-transform-origin: center center;
  transform-origin:         center center;
  width:                    100%;
  position:                 absolute;
  top:                      0;
  bottom:                   0;
  left:                     0;
  right:                    0;
  margin:                   auto;
}

.path {
  stroke-dasharray:  1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  animation:         dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap:    round;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform:         rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform:         rotate(360deg);
  }
}

@-webkit-keyframes dash {
  0% {
    stroke-dasharray:  1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray:  89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray:  89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray:  1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray:  89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray:  89, 200;
    stroke-dashoffset: -124px;
  }
}

@-webkit-keyframes color {
  100%,
  0%  { stroke: #d62d20; }
  40% { stroke: #0057e7; }
  66% { stroke: #008744; }
  80%, 90% { stroke: #ffa700; }
}

@keyframes color {
  100%,
  0%  { stroke: #d62d20; }
  40% { stroke: #0057e7; }
  66% { stroke: #008744; }
  80%, 90% { stroke: #ffa700; }
}
